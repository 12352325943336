import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { httpEndpoint, getHttpLink } from '@/providers/apollo/http-link'

// Install the vue plugin
Vue.use(VueApollo)

// Files URL root
export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

Vue.prototype.$filesRoot = filesRoot

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wssEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'wss://hulk-beta.mercafacil.com/graphql',
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  httpLinkOptions: {
    uri: httpEndpoint,
  },
  defaultHttpLink: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: getHttpLink(),

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })

  apolloClient.wsClient = wsClient
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      apolloClient,
    },
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.error('GQL ERROR - %cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error)
      if (error.graphQLErrors.some((error) => error.message.startsWith('authentication.error'))) {
        window.location.replace('/#/expired-session')
      }
      if (error.graphQLErrors.some((error) => error.message.startsWith('invalid.session'))) {
        window.location.replace('/#/expired-session')
      }
    },
  })

  return apolloProvider
}
