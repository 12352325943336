import { defineStore } from 'pinia'

import state from '@/store/state.js'
import getters from '@/store/getters.js'
import actions from '@/store/actions.js'

export const useStore = defineStore('main', {
  state,
  getters,
  actions,
})
