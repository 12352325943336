import { enums } from '@mf-node/mf-vue-components'

import { getAncestorOrigin } from '@/helpers/ancestorOrigins'

const jwt = require('jsonwebtoken')

export default {
  install(Vue) {
    // Logged User

    const setUserPermissions = (permissions) => {
      if (!permissions) return
      window.top.postMessage(
        {
          type: 'setUserPermissions',
          permissions,
        },
        getAncestorOrigin()
      )
    }

    const setFingerPrint = (fingerprint) => {
      window.top.postMessage(
        {
          type: enums.postMessageTypes.getPostMessageType('FINGERPRINT'),
          fingerprint,
        },
        getAncestorOrigin()
      )
      window.localStorage.setItem('fingerprint', JSON.stringify(fingerprint))
    }

    // Session

    const setSessionId = (sessionId) => {
      if (!sessionId) return
      window.top.postMessage(
        {
          type: 'setSessionId',
          sessionId,
        },
        getAncestorOrigin()
      )
      window.localStorage.setItem('session-id', sessionId)
    }

    // Is MultiUser

    const setIsMultiUser = (isMultiUser) => {
      window.localStorage.setItem('is-multi-user', jwt.sign({ isMultiUser }, process.env.VUE_APP_JWT_KEY))
    }

    const getIsMultiUser = () => {
      try {
        let { isMultiUser } = jwt.verify(window.localStorage.getItem('is-multi-user'), process.env.VUE_APP_JWT_KEY)
        return isMultiUser
      } catch {
        return false
      }
    }

    // Is Admin

    const setIsAdmin = (isAdmin) => {
      window.top.postMessage(
        {
          type: 'setIsAdmin',
          isAdmin,
        },
        getAncestorOrigin()
      )
      window.localStorage.setItem('is-admin', jwt.sign({ isAdmin }, process.env.VUE_APP_JWT_KEY))
    }

    const getIsAdmin = () => {
      try {
        let { isAdmin } = jwt.verify(window.localStorage.getItem('is-admin'), process.env.VUE_APP_JWT_KEY)
        return isAdmin
      } catch {
        return false
      }
    }

    // Logged User
    Vue.prototype.$setUserPermissions = Vue.setUserPermissions = setUserPermissions
    Vue.prototype.$setFingerPrint = Vue.setFingerPrint = setFingerPrint

    // Session ID
    Vue.prototype.$setSessionId = Vue.setSessionId = setSessionId

    // Is Admin
    Vue.prototype.$getIsAdmin = Vue.getIsAdmin = getIsAdmin
    Vue.prototype.$setIsAdmin = Vue.setIsAdmin = setIsAdmin

    // Is MultiUser
    Vue.prototype.$setIsMultiUser = Vue.setIsMultiUser = setIsMultiUser
    Vue.prototype.$getIsMultiUser = Vue.getIsMultiUser = getIsMultiUser
  },
}
