import { getAuthSource } from '@/helpers/authSource'
import { getAncestorOrigin } from '@/helpers/ancestorOrigins'

export default {
  async setCurrentSource() {
    const ancestorOrigin = getAncestorOrigin()
    this.source = getAuthSource(ancestorOrigin)
  },

  async setRedirectUrlAfterAutentication(referrerUrl, redirectURL) {
    this.redirectUrlAfterAutentication = redirectURL
  },

  redirectToNextStep(authCode) {
    window.location.href = `${this.redirectUrlAfterAutentication}?auth_code=${authCode}`
  },

  async setDirectRedirect(directRedirect) {
    this.directRedirect = directRedirect
  },
}
