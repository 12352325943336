<template>
  <v-snackbar v-model="open" class="snackbar ml-8" :color="snackbarColor" left :timeout="timeout">
    <span class="snackbar-text">{{ message }}</span>
    <mf-icon v-if="!btnText" class="cursor-pointer" color="branco" @click.stop="closeSnackbar()">close</mf-icon>
    <mf-button v-else class="mr-2" :color="btnColor" :dark="false" :label="btnText" text @click="clickButton()" />
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    }
  },
  watch: {
    open(value) {
      if (!value) {
        this.closeSnackbar()
      }
    },
  },
}
</script>
<style lang="scss">
.snackbar {
  font-family: 'Lato', sans-serif;
  z-index: 999999 !important;
}
</style>
