import { MUTATION_SINGLE_LOGIN, QUERY_AUTH_CODE, MUTATION_LOGOUT, QUERY_ME } from '@/modules/login/graphql'

// TODO todas as chamadas devem ser passadas por actions ?

export default {
  async singleLogin(payload, apolloService) {
    const response = await apolloService.mutate({
      mutation: MUTATION_SINGLE_LOGIN,
      variables: payload,
    })

    return response
  },

  async getCode(apolloService, payload) {
    const {
      data: { token },
    } = await apolloService.query({
      query: QUERY_AUTH_CODE,
      fetchPolicy: 'no-cache',
      variables: payload,
    })
    return token
  },

  async singleLogout(apolloService) {
    await apolloService.mutate({
      mutation: MUTATION_LOGOUT,
    })
  },

  async getMe(apolloService) {
    const {
      data: { me },
    } = await apolloService.query({
      query: QUERY_ME,
    })
    return me.multiAccount
  },
}
