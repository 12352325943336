import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/modules/login/views/pages/Login.vue'),
      meta: {
        title: 'Login',
      },
      beforeEnter: (to, from, next) => {
        const queryStrings = window.location.href.slice(window.location.href.indexOf('?') + 1).split('=')

        if (!!window.localStorage.getItem('redirect-url') || !!queryStrings[1]) {
          next()

          return
        }

        next({ name: 'not-found' })
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/modules/login/views/pages/Login.vue'),
      meta: {
        title: 'Login',
      },
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      component: () => import('@/modules/login/views/pages/ConfirmEmail.vue'),
      meta: {
        title: 'Confirmação de e-mail',
      },
    },
    {
      path: '/5b019f98b99872b1355a46e5afd7911c',
      name: 'DownloadFile',
      component: () => import('@/components/AmbevHash.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/components/NotFound.vue'),
    },
  ],
})

export default router
