<template>
  <v-app id="app">
    <v-main>
      <v-container v-if="!loading" class="pa-0" fluid>
        <transition-group name="slide-up">
          <div key="page">
            <router-view class="child-view" />
          </div>
        </transition-group>
      </v-container>
      <mf-loading-dialog v-else loading />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useStore as mainUseStore } from '@/store'
import { useStore as loginUseStore } from '@/modules/login/store'

export default {
  data: () => ({
    loading: true,
    isRedirecting: false,
  }),
  computed: {
    ...mapState(mainUseStore, {
      source: 'getCurrentSource',
    }),
  },
  async mounted() {
    this.$setIsMultiUser(false)
    let oldReferrer

    if (window.localStorage.getItem('redirect-url')) {
      oldReferrer = JSON.parse(window.localStorage.getItem('redirect-url'))
    }

    localStorage.clear()

    if (oldReferrer) {
      window.localStorage.setItem('redirect-url', JSON.stringify(oldReferrer))
    }

    const referrerURL = await this.getReferrerURL()
    const redirectURLParams = await this.getRedirectURLParams()

    if (referrerURL || redirectURLParams) {
      this.setRedirectUrlAfterAutentication(referrerURL, redirectURLParams)
    }

    await this.setCurrentSource()

    if (this.isLogout()) {
      await this.singleLogout(this.$apollo)
    } else {
      const validAuthCode = await this.checkIsCookieValid()

      if (validAuthCode) {
        const multiUser = await this.getMe(this.$apollo)
        if (multiUser) {
          this.$setIsMultiUser(true)
          this.loading = false
          this.$router.push({ name: 'login' })
          return
        }

        await this.redirectToNextStep(validAuthCode)
        this.isRedirecting = true
      }
    }

    if (!this.isRedirecting) this.loading = false
    if (this.$route.fullPath === '/') return
    if (this.$route.name === 'confirm-email') return
    this.$router.push({ name: 'login' })
  },
  methods: {
    ...mapActions(mainUseStore, ['setCurrentSource', 'setRedirectUrlAfterAutentication', 'redirectToNextStep', 'setDirectRedirect']),
    ...mapActions(loginUseStore, ['getCode', 'singleLogout', 'getMe']),

    async checkIsCookieValid() {
      try {
        const payload = {
          source: this.source,
        }
        const { authCode } = await this.getCode(this.$apollo, payload)
        return authCode
      } catch {
        return false
      }
    },

    isLogout() {
      return this.$router?.history?.pending?.name === 'logout'
    },

    getReferrerURL() {
      return document.referrer
    },

    getRedirectURLParams() {
      const queryStrings = window.location.href.slice(window.location.href.indexOf('?') + 1).split('=')
      const redirectUrl = queryStrings[1] && queryStrings[1].length ? queryStrings[1].replace('&redirect', '') : queryStrings[1]

      if (queryStrings.includes('redirect=true') || queryStrings.includes('true')) {
        this.setDirectRedirect(true)
      }

      if (
        queryStrings[0] === 'redirect_url' &&
        ((redirectUrl && !window.localStorage.getItem('redirect-url')) ||
          (redirectUrl && redirectUrl !== JSON.parse(window.localStorage.getItem('redirect-url'))))
      ) {
        window.localStorage.setItem('redirect-url', JSON.stringify(redirectUrl))
      }

      return redirectUrl ?? JSON.parse(window.localStorage.getItem('redirect-url'))
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/global.scss';

.v-application {
  font-family: 'Lato', sans-serif !important;

  .title,
  .subtitle {
    font-family: 'Lato', sans-serif !important;
  }
}
html {
  overflow: auto !important;
}
body {
  background-color: #f3f3f3 !important;
}
.primary-text {
  opacity: 0.87 !important;
}
.secondary-text {
  opacity: 0.54 !important;
}
</style>
