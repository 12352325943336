import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { enums } from '@mf-node/mf-vue-components'

import { getAuthSource } from '@/helpers/authSource'
import { getAncestorOrigin } from '@/helpers/ancestorOrigins'

import i18n from '../i18n'

const source = getAuthSource(getAncestorOrigin())

const themes =
  source === enums.authSources.getAuthSource('ADMIN') || source === enums.authSources.getAuthSource('AMBEV')
    ? enums.assetsSources.getAssetsSource(source).theme().themes
    : enums.assetsSources.getAssetsSource(source).theme

const vuetifyOptions = {
  theme: {
    dark: false,
    themes,
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
}

Vue.use(Vuetify)

export default new Vuetify(vuetifyOptions)
