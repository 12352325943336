import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
import VueShortkey from 'vue-shortkey'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import router from './router'
import Snackbar from './plugins/snackbar/snackbar.js'
import Microservices from '@/plugins/microservices/microservices.js'
import LoggedUser from './plugins/logged-user/loggedUser.js'
import Whitelabel from './plugins/whitelabel/whitelabel.js'
import { createPinia, PiniaVuePlugin } from 'pinia'
import './plugins/mf-vue-components'
import 'regenerator-runtime/runtime'
import 'sweetalert2/dist/sweetalert2.min.css'

// Graphql
import { apolloProvider } from '@/apollo-provider.js'

import App from './App.vue'

Vue.use(Whitelabel, { env: process.env })
Vue.use(Microservices, { env: process.env })
Vue.use(VueShortkey)
Vue.use(Snackbar, { vuetify })
Vue.use(VueTheMask)
Vue.use(LoggedUser)
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
  pinia,
  vuetify,
  i18n,
  router,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app')
