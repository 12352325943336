import { enums } from '@mf-node/mf-vue-components'

export const getAuthSource = (origin) => {
  if (!origin) {
    if (window.localStorage.getItem('redirect-url')) origin = JSON.parse(window.localStorage.getItem('redirect-url'))
  }
  if (origin && origin.match('storybook([^:]*).mercafacil.com')) return enums.authSources.getAuthSource('STORYBOOK')
  if (origin && origin.match('developers([^:]*).mercafacil.com')) return enums.authSources.getAuthSource('INTEGRATION_DEVELOPER')
  if (origin && origin.match('starlord([^:]*).mercafacil.com')) return enums.authSources.getAuthSource('STARLORD')
  if (origin && origin.match('ambev([^:]*).mercafacil.com')) return enums.authSources.getAuthSource('AMBEV')
  return enums.authSources.getAuthSource('ADMIN')
}
