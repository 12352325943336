import gql from 'graphql-tag'

export const MUTATION_ADMIN_LOGIN = gql`
  mutation AdminLoginWithGoogle($sourceLoginUrl: String = "", $token: String!) {
    adminLoginWithGoogle(sourceLoginUrl: $sourceLoginUrl, token: $token) {
      emailConfirmed
      deviceConfirmationToken
      deviceConfirmed
      multiUser
    }
  }
`

export const QUERY_USER_ACCOUNTS = gql`
  query UserAccounts($cursor: String, $limit: Float, $search: String) {
    userAccounts(cursor: $cursor, limit: $limit, search: $search) {
      cursor
      accounts {
        dbName
        id
        idExternal
        name
        statusAccount {
          adjusting
          churn
          deploying
          deprecated
          done
          impossibleSupport
          temporaryChurn
          test
        }
        flags {
          industry {
            has_mercapromo
            only_mercapromo
          }
        }
      }
    }
  }
`

export const MUTATION_SELECT_USER_ACCOUNT = gql`
  mutation SelectUserAccount($accountId: String!) {
    selectUserAccount(accountId: $accountId) {
      message
    }
  }
`

export const QUERY_CHECK_AVAILABLE_EMAIL = gql`
  query EmailIsAvailable($email: String!) {
    emailIsAvailable(email: $email) {
      result
    }
  }
`

export const QUERY_VALIDATE_EMAIL_CONFIRMATION_TOKEN = gql`
  query EmailConfirmationToken($token: String!) {
    emailConfirmationToken(token: $token) {
      email
    }
  }
`

export const MUTATION_REQUEST_EMAIL_CONFIRMATION = gql`
  mutation SendUserEmailConfirmation($email: String!, $password: String!) {
    sendUserEmailConfirmation(email: $email, password: $password) {
      message
    }
  }
`

export const MUTATION_CONFIRM_EMAIL_AND_CHANGE_PASSWORD = gql`
  mutation ConfirmUserEmailAndChangePassword($email: String!, $oldPassword: String!, $newPassword: String!) {
    confirmUserEmailAndChangePassword(email: $email, oldPassword: $oldPassword, newPassword: $newPassword) {
      message
    }
  }
`

export const QUERY_CONFIRM_PASSWORD = gql`
  query PasswordIsValid($password: String!, $token: String!) {
    passwordIsValid(password: $password, token: $token) {
      result
    }
  }
`

export const MUTATION_CONFIRM_DEVICE_CODE = gql`
  mutation ConfirmUserDevice($confirmationToken: String!, $saveDevice: Boolean!, $verificationCode: String!) {
    confirmUserDevice(confirmationToken: $confirmationToken, saveDevice: $saveDevice, verificationCode: $verificationCode) {
      message
    }
  }
`
export const MUTATION_RESEND_CONFIRM_CODE = gql`
  mutation ResendUserDeviceConfirmation($deviceConfirmationToken: String!, $phoneNumber: String, $senderType: SenderTypeEnum) {
    resendUserDeviceConfirmation(deviceConfirmationToken: $deviceConfirmationToken, phoneNumber: $phoneNumber, senderType: $senderType) {
      deviceConfirmationToken
    }
  }
`

export const MUTATION_SINGLE_LOGIN = gql`
  mutation Login($login: String!, $password: String!, $source: String) {
    login: loginSession(login: $login, password: $password, source: $source) {
      emailConfirmed
      deviceConfirmationToken
      deviceConfirmed
      multiUser
      hiddenPhone
    }
  }
`

export const QUERY_AUTH_CODE = gql`
  query Token($source: String) {
    token(source: $source) {
      authCode
    }
  }
`

export const QUERY_ME = gql`
  query Me {
    me: meUser {
      multiAccount
    }
  }
`

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    logout: logoutSession {
      message
    }
  }
`

export const QUERY_VALIDATE_USER_BY_EMAIL = gql`
  query ValidateUserByEmail($email: String!) {
    validateUserByEmail(email: $email) {
      has_phone
      hidden_phone
      passwordRecoveryToken
    }
  }
`

export const QUERY_VALIDATE_PASSWORD_CONFIRMATION_TOKEN = gql`
  query RecoveryPasswordConfirmationToken($confirmationToken: String!, $verificationCode: String!) {
    recoveryPasswordConfirmationToken(confirmationToken: $confirmationToken, verificationCode: $verificationCode)
  }
`

export const MUTATION_CHANGE_PASSWORD_BY_EMAIL = gql`
  mutation ChangePasswordByEmail($email: String!, $newPassword: String!) {
    changePasswordByEmail(email: $email, newPassword: $newPassword) {
      message
    }
  }
`

export const QUERY_VALIDATE_USER_BY_PHONE = gql`
  query ValidateUserByPhone($phone: String!) {
    validateUserByPhone(phone: $phone) {
      passwordRecoveryToken
    }
  }
`
