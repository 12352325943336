import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'

// Http endpoint
export const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://plat-user-svc-beta.mercafacil.com/graphql'

export const getHttpLink = () => {
  const httpLink = createUploadLink({ uri: httpEndpoint, credentials: 'include' })
  let fingerprint

  // Auth middleware
  const authLink = setContext((request, { headers = {} } = {}) => {
    // By spreading after the authorization key, if a query passes
    // a specific authorization header, it will overwrite the default header
    return {
      headers: {
        ...headers,
      },
    }
  })

  // Fingerprint middleware
  const fingerprintLink = setContext(async (request, { headers = {} } = {}) => {
    // Resolve the fingerprint only in the first request
    if (!fingerprint) {
      const fingerprintLocalStorage = JSON.parse(window.localStorage.getItem('fingerprint'))
      fingerprint = fingerprintLocalStorage?.visitorId
    }
    // By spreading after the fingerprint key, if a query passes
    // a specific fingerprint header, it will overwrite the default header
    return {
      headers: {
        fingerprint,
        ...headers,
      },
    }
  })

  return fingerprintLink.concat(authLink.concat(httpLink))
}
